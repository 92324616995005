import React, { useState } from "react";
// import home1 from '../../images/Group309.png'
import Layoutas from '../components/Layout';
import Chart from '../components/Chart';
import { Affix, Button } from 'antd';

const Index = () => {
  const [top, setTop] = useState(10);
  const [bottom, setBottom] = useState(10);
  return (
    <Layoutas>
      <div style={{
        width: '100%',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        // height: '100vh',
        // backgroundImage: `url(${home1})`,
        justifyContent: 'center',
        // alignItems: 'flex-end',
        flexDirection: 'column',
        display: 'flex'
      }}>
        <div className='h-[120px]'></div>
        <div className="max-w-[1300px] 2xl:max-w-[1400px]  rounded-3xl m-auto p-8 bg-white bordered">
          <Affix offsetTop={top}>
            <Button type="primary" style={{ marginTop: 100 }} onClick={() => setTop(top + 10)}>
              Affix top
            </Button>
          </Affix>
          <div className="font-bold text-2xl mb-2 text-[#0E3165]">ข้อมูลรายได้ครัวเรือน</div>
          <p>ข้อมูลรายได้เฉลี่ยของสมาชิกในครัวเรือนและจำนวนเฉลี่ยของสมาชิกในครัวเรือนตั้งแต่ปีการศึกษา 2/2560 – 1/2562</p>

          <Chart
            options={{
              title: '',
              type: 'column',
              categories: ['2563', '2564'],
              yAxis: {
                title: {
                  text: 'จำนวนนักเรียน (คน)'
                }
              }
            }}
            click={({ e, category, name }) => {
              // showModal()
              // setColumns(confixColumnsTable.studentSchool)
              // setDataTable(mapDataTable('table1', dataSummaryAllyear, category))
              // setTitle('จำนวนนักเรียนทั้งหมดในประเทศไทย ปีการศึกษา ' + category)
            }}
            containerProps={{ style: { height: "260px" } }}
            style={{ width: '100px', height: '320px' }}
            data={[{
              name: 'เหนือ',
              color: '#2dd4bf',
              data: [10, 0]
            },
            {
              name: 'ใต้',
              color: '#38bdf8',
              data: [10, 0]
            },
            {
              name: 'กลาง',
              color: '#818cf8',
              data: [10, 0]
            },
            {
              name: 'ตะวันออกเฉียงเหนือ',
              color: '#FFADF0',
              data: [20, 0]
            }
            ]}
          // school.studentStatistics
          />
          {/* ปีการศึกษา	รายได้ครัวเรือนเฉลี่ย(บาท) */}
          <div className="flex flex-row w-full">
            <div className="w-4/5">
              <table class="table-auto">
                <thead>
                  <tr>
                    <th>ปีการศึกษาปีการศึกษา</th>
                    <th>รายได้ครัวเรือนเฉลี่ย(บาท)</th>
                    <th>จำนวนสมาชิกเฉลี่ย(คน)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>2/2560</td>
                    <td>1,347.75</td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>2/2560</td>
                    <td>1,347.75</td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>2/2560</td>
                    <td>1,347.75</td>
                    <td>4</td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>



          <div className="font-bold text-2xl mb-2 text-[#0E3165]">สภาวะยากลำบาก</div>
          <p> ในปีการศึกษา 2/2560 – 1/2562 นักเรียนยากจนและยากจนพิเศษแต่ละคนต่างกมีสภาวะความยากลำบากที่แตกต่างกันไป เช่น การมีภาระพึ่งพิง สภาพบ้านชำรุด ไม่มียานพาหนะ และไม่มีที่ดินทำกิน โดยการมีภาระพึ่งพิงหมายถึง การมีคนพิการ/เจ็บป่วยเรื้อรัง ผู้สูงอายุเกินกว่า 60 ปี มีคนอายุ 15-65 ปีที่ว่างงาน หรือเป็นพ่อ/แม่เลี้ยงเดี่ยว สภาพบ้านชำรุดทรุดโทรม หมายถึง มีสภาพไม่ปลอดภัย ไม่มีห้องส้วมในที่อยู่อาศัย หรือบริเวณบ้าน ยานพาหนะในครัวเรือน หมายถึง การครอบครองยานพาหนะของครัวเรือนนั้น ๆ และที่ดินทำกิน หมายถึง การมีที่ดินที่ใช้ในการทำกิน ไม่ว่าจะเป็นที่ดินของตนเองหรือเช่ามาจากผู้อื่นก็ตาม โดยจำแนกออกเป็น ไม่มีที่ดินทำกิน มีที่ดินทำกินไม่เกิน 1 ไร่ และมีที่ดินทำกินได้ 1 ถึง 5 ไร่</p>

          <Chart
            options={{
              title: '',
              type: 'column',
              categories: ['2563', '2564'],
              yAxis: {
                title: {
                  text: 'จำนวนนักเรียน (คน)'
                }
              }
            }}
            click={({ e, category, name }) => {
              // showModal()
              // setColumns(confixColumnsTable.studentSchool)
              // setDataTable(mapDataTable('table1', dataSummaryAllyear, category))
              // setTitle('จำนวนนักเรียนทั้งหมดในประเทศไทย ปีการศึกษา ' + category)
            }}
            containerProps={{ style: { height: "260px" } }}
            style={{ width: '100px', height: '320px' }}
            data={[{
              name: 'เหนือ',
              color: '#2dd4bf',
              data: [10, 0]
            },
            {
              name: 'ใต้',
              color: '#38bdf8',
              data: [10, 0]
            },
            {
              name: 'กลาง',
              color: '#818cf8',
              data: [10, 0]
            },
            {
              name: 'ตะวันออกเฉียงเหนือ',
              color: '#FFADF0',
              data: [20, 0]
            }
            ]}
          // school.studentStatistics
          />

          <div className="font-bold text-2xl mb-2 text-[#0E3165]">แหล่งไฟฟ้าและเครื่องใช้ไฟฟ้า</div>
          <p>ในส่วนของแหล่งไฟฟ้าและเครื่องใช้ไฟฟ้าสำหรับครัวเรือนแล้วเป็นปัจจัยที่สำคัญมากเช่นกัน โดยแต่ละครัวเรือนจะมีแหล่งไฟฟ้าสำหรับการใช้งานที่แตกต่างกัน เช่น ใช้เครื่องปั่นไฟ ใช้ไฟต่อพ่วง ใช้ไฟมิเตอร์และอื่นๆ แต่ในขณะเดียวกันก็ยังมีบางครัวเรือนที่ไม่มีไฟฟ้าและไม่มีเครื่องใช้ไฟฟ้าภายในครัวเรือน เครื่องใช้ไฟฟ้าในที่นี้ จะหมายถึง เครื่องปรับอากาศ โทรทัศน์ คอมพิวเตอร์ ตู้เย็น และเครื่องซักผ้า ซึ่งเครื่องใช้ไฟฟ้าเหล่านี้จะต้องยังสามารถใช้งานได้ตามปกติ ไม่ชำรุดเสียหาย</p>


          <div className="font-bold text-2xl mb-2 text-[#0E3165]">แหล่งน้ำที่ใช้ในครัวเรือน</div>
          <p> ในแต่ละครัวเรือนจำเป็นต้องมีแหล่งน้ำที่ใช้ภายในครัวเรือน โดยแหล่งน้ำเหล่านั้น มีที่มาแตกต่างกันไป ไม่ว่าจะเป็นน้ำประปา น้ำบ่อ/น้ำฝน/น้ำประปาภูเขา/แม่น้ำลำธาร น้ำบาดาล และจากแหล่งน้ำอื่น ๆ
          </p>

        </div >
      </div>
    </Layoutas >
  )
}

export default Index
